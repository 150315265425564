import React, { Component } from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import { fallDown as Menu } from 'react-burger-menu'
import styles from './hamburger.module.scss'
import LogoInline from '../../images/logo.inline.svg'

// Burger styles
const burgerStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '70px',
    height: '56px',
    top: '50px',
    zIndex: '1110',
    outline: 'none',
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    width: '36px',
    height: '36px',
    right: '42px',
    top: '34px',
  },
  bmCross: {
    background: '#bdc3c7',
    width: '7px',
    height: '24px',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    width: '100%',
  },
  bmMenu: {
    background: 'linear-gradient( to bottom, rgba(49, 72, 158, 1), rgba(49, 72, 158, 0.8) )',
    fontSize: '1.15em',
    display: 'flex',
    justifyContent: 'center'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
    flex: '1 1 auto',
    textAlign: 'center',
    paddingTop: '28px',
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    width: '100%',
  }
}

// Output our link array
const ListLinks = (props) => {
  const listItems = props.nav.map((link, i) =>
    <li key={i}>
      <Link to={link.url.replace('http://157.245.172.255/', '/')} target={link.target} dangerouslySetInnerHTML={{ __html: link.title }} />
    </li>
  )
  return (
    <ul className={styles.ul}>{listItems}</ul>
  )
}

const HamButton = (props) => {
  return(
  <div>
    <a href={props.donate.url} className={styles.buttonDonate}>{props.donate.title}</a>
    <a href={props.getSupp.url} className={styles.buttonGet}>{props.getSupp.title}</a>
    <a href={props.getSupp.url} className={styles.buttonGetMobile}>Get</a>
    <button className={`hamburger hamburger--emphatic ${props.active ? 'is-active' : ''}`} id="js-navbar-toggle" type="button">
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  </div>
  )
}
class Hamburger extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false,
      fromEdge: '0px',
      fromTop: '50px'
    }
  }
  
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu () {
    this.setState({menuOpen: false})
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu () {
    this.setState(state => ({ 
      menuOpen: !state.menuOpen, 
    }))
  }
  
  burgerPos (cW, wW, annc, hdrLogo) {
    this.setState({ fromEdge: cW.offsetLeft + 'px'})
    this.setState({ fromTop: ((annc.offsetHeight + (hdrLogo.offsetHeight / 2)) - 28) + 'px' })
    // console.log( ((annc.offsetHeight + (hdrLogo.offsetHeight / 2)) - 28) + 'px' )
  }

  componentDidMount() {
    const container = document.querySelector('.container')
    const windowWidth = window.innerWidth
    const annc = document.getElementById('announcements')
    const hdrLogo = document.getElementById('hdrLogo')
    this.burgerPos(container, windowWidth, annc, hdrLogo)
    window.addEventListener('resize', () => {
      this.burgerPos(container, windowWidth, annc, hdrLogo)
    })
  }

  render() {
    burgerStyles.bmBurgerButton.right = this.state.fromEdge
    burgerStyles.bmBurgerButton.top = this.state.fromTop
    return (
      <div>
        <Menu
          right
          noOverlay
          styles={ burgerStyles }
          customBurgerIcon={ <HamButton active={ this.state.menuOpen } donate={ this.props.donate } getSupp={ this.props.getSupp } /> }
          customCrossIcon={ false }
          isOpen={ this.state.menuOpen }
          onStateChange={ (state) => this.handleStateChange(state) }
        >
          <Link to="/">
            <LogoInline className={styles.logoInline} />
          </Link>
          <ListLinks nav={ this.props.menu } />
        </Menu>
      </div>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query mainNavQuery {
        allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "main-menu"}}) {
          edges {
            node {
              slug
              items {
                object_slug
                title
                target
                url
              }
            }
          }
        }
        wordpressAcfOptions {
          options {
            option_donate {
              url
              title
              target
            }
            option_get {
              url
              title
              target
            }
          }
        }
      }            
    `}
    render={data => {
      return (
        <Hamburger
          menu={data.allWordpressWpApiMenusMenusItems.edges[0].node.items}
          donate={data.wordpressAcfOptions.options.option_donate}
          getSupp={data.wordpressAcfOptions.options.option_get}
        />
      )
    }}
  />
)