import React, { useEffect } from "react"
import styles from "./logo.module.scss"
import gsap, { TimelineMax } from "gsap"

const Logo = () => {

    useEffect(() => {
        const tl = new TimelineMax();
        gsap.set("#MTALogo", { css: { opacity: 1 } })
        tl.staggerFromTo("#alaskaBlocks polygon", 1, { scale: 4, opacity: 0 }, { scale: 1, opacity: 1, stagger: 0.2, delay: -.1 } );
        tl.to("#mtaLetters path", 2, {css: { strokeDashoffset: 0 }, delay: -1.5 } );
        tl.to("#mtaLetters path", 1, {css: { fillOpacity: 1 }, delay: -1}  );
    }, []);
    //const jiggleBounce = useRef(null);

    return (
        <svg id="MTALogo" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 643.5 136.3" role="img" aria-label="MTA Foundation Logo Animated block style Alaska state followed by MTA Foundation" 
            className={styles.logo_svg}>
            <title>MTA Foundation Logo</title>
            <desc>Animated block style Alaska state followed by MTA Foundation</desc>
                <g id="alaskaBlocks" className={styles.alaskaBlocks}>
                    <polygon className="akBlock block01" points="60.3,99.5 47.6,56.7 28.7,68.2 45.8,99.6 0,122.4 1.5,125.9 1.5,126" />
                    <polygon className="akBlock block02" points="48.1,45.6 50.6,36.7 33.5,32.9 32,38.5" />
                    <polygon className="akBlock block03" points="89.7,9.2 86.2,5 56.9,36.1 56.3,38.3 54.7,43.9 52.5,52.1 65.8,97" />
                    <polygon className="akBlock block04" points="50.5,9.9 46.8,13.9 56.9,27.4 80.8,2.1 73.3,0 57.1,11.7" />
                    <polygon className="akBlock block05" points="95,12.2 76.2,80.8 114.9,85.5 115.5,17.8" />
                    <polygon className="akBlock block06" points="114.7,102.3 114.8,91.8 82.4,87.9 86.7,95.4" />
                    <polygon className="akBlock block07" points="120.6,92.5 120.5,104.7 152.5,136.3 161.5,133.3" />
                </g>
                <g id="mtaLetters" className={styles.mtaLetters}>
                    <path className="mta" id="akBlock01" d="M193.4,32.1c0.4,0,0.9,0.4,0.9,0.9v41.2c0,0.4-0.4,0.9-0.9,0.9h-4.6c-0.4,0-0.9-0.4-0.9-0.9
                        V43.2h-0.2c0,0-0.9,2.4-2.5,4.6l-15.2,21.7l-15.2-21.7c-1.6-2.2-2.5-4.6-2.5-4.6h-0.2v30.9c0,0.4-0.4,0.9-0.9,0.9h-4.6
                        c-0.4,0-0.9-0.4-0.9-0.9V33c0-0.4,0.4-0.9,0.9-0.9h2.9c0.5,0,1,0.2,1.4,0.7l19,27h0.1l19-27c0.4-0.5,0.9-0.7,1.4-0.7H193.4z"/>
                    <path className="mta" d="M201.9,36.6v-3.4c0-0.4,0.6-1,1-1h40.2c0.4,0,1,0.6,1,1v3.4c0,0.4-0.6,1-1,1h-16.9v36.6
                        c0,0.4-0.4,0.9-0.9,0.9h-4.6c-0.4,0-0.9-0.4-0.9-0.9V37.6h-16.9C202.5,37.6,201.9,37,201.9,36.6"/>
                    <path className="mta" d="M252.5,60.7h18.4l-5.7-11.4c-1.1-2.1-3.2-7.9-3.2-7.9h-0.4c0,0-2.1,5.7-3.2,7.9L252.5,60.7z
                        M273.6,66.2h-23.8l-3.9,7.9c-0.5,0.9-0.6,0.9-1.5,0.9h-4.6c-0.4,0-0.6-0.3-0.6-0.5c0-0.2,0.2-0.6,0.4-0.9l21-41.5h2.6l21,41.5
                        c0.1,0.3,0.4,0.6,0.4,0.9c0,0.2-0.2,0.5-0.6,0.5h-4.6c-0.9,0-1,0-1.5-0.9L273.6,66.2z"/>
                    <path className="foundation" d="M308.1,75c-0.3,0-0.6-0.3-0.6-0.6V32.7c0-0.3,0.3-0.6,0.6-0.6H339c0.3,0,0.6,0.4,0.6,0.6v2.3
                        c0,0.3-0.4,0.6-0.6,0.6h-27.4v16.8h24.6c0.3,0,0.6,0.4,0.6,0.6v2.3c0,0.3-0.4,0.6-0.6,0.6h-24.6v18.4c0,0.3-0.3,0.6-0.6,0.6H308.1z"
                        />
                    <path className="foundation" d="M347.4,60.4c0,7.4,5.3,11.8,11.9,11.8c6.6,0,11.9-4.4,11.9-11.8c0-7.3-5.3-11.8-11.9-11.8
                        C352.6,48.6,347.4,53.1,347.4,60.4 M375.2,60.4c0,9.8-7.4,15.4-15.9,15.4c-8.6,0-15.9-5.6-15.9-15.4c0-9.7,7.4-15.4,15.9-15.4
                        C367.9,45,375.2,50.7,375.2,60.4"/>
                    <path className="foundation" d="M408,69.6c-2.2,3.1-6.4,6.1-12.2,6.1c-8.1,0-12.1-5.3-12.1-12.7V46.5c0-0.3,0.4-0.8,0.7-0.8
                        h2.6c0.3,0,0.7,0.5,0.7,0.8V63c0,5.6,2.9,9.1,8.4,9.1c5.3,0,10.2-3.6,12.2-8.1V46.5c0-0.3,0.4-0.8,0.7-0.8h2.6
                        c0.3,0,0.7,0.5,0.7,0.8v27.8c0,0.3-0.4,0.7-0.7,0.7h-1.9c-0.3,0-0.7-0.4-0.8-1l-0.6-4.4H408z"/>
                    <path className="foundation" d="M446.4,56.4c0-4.6-3.1-7.9-8.4-7.9c-5.8,0-10.2,3.6-12.2,8.1v17.7c0,0.3-0.4,0.7-0.7,0.7h-2.6
                        c-0.3,0-0.7-0.4-0.7-0.7V46.5c0-0.3,0.4-0.8,0.7-0.8h1.9c0.3,0,0.6,0.2,0.7,1l0.7,4.6h0.2c2.1-3.3,6.6-6.3,12.1-6.3
                        c8.1,0,12.2,4.7,12.2,11.4v17.9c0,0.3-0.4,0.7-0.7,0.7h-2.6c-0.3,0-0.7-0.4-0.7-0.7V56.4z"/>
                    <path className="foundation" d="M473.8,72.2c4.8,0,8.6-2.4,11.2-5.6V56.2c-1.9-4.5-6-7.6-11.2-7.6c-6.1,0-10.9,4.6-10.9,11.8
                        C462.9,67.7,467.7,72.2,473.8,72.2 M484.8,70.8c-2.4,2.7-6.4,4.9-11.4,4.9c-7.4,0-14.6-5.3-14.6-15.4c0-8.9,6.3-15.4,14.9-15.4
                        c5.5,0,9.1,2.4,11,5.1h0.1V28.6c0-0.3,0.4-0.8,0.7-0.8h2.6c0.3,0,0.7,0.5,0.7,0.8v45.7c0,0.3-0.4,0.7-0.7,0.7h-1.9
                        c-0.4,0-0.6-0.2-0.8-0.9l-0.6-3.3H484.8z"/>
                    <path className="foundation" d="M522.8,67.2v-4.6h-13.3c-6,0-7.6,2.6-7.6,4.9c0,2.9,2.6,4.8,7,4.8
                        C514.4,72.2,519.6,69.8,522.8,67.2 M522.6,70.9c-3.2,2.6-7.8,4.9-13.7,4.9c-7.3,0-11-3.7-11-8.4c0-4.4,3.4-8.1,11.7-8.1h13.3v-1.6
                        c0-5.2-2.4-9.1-10.8-9.1c-6.1,0-9.1,2.1-9.1,6.5v0.4c0,0.3-0.4,0.4-0.7,0.4h-2.6c-0.3,0-0.7-0.5-0.7-0.8v-0.4
                        c0-6.1,4.4-9.7,13.1-9.7c10.9,0,14.8,5.6,14.8,13v16.3c0,0.3-0.4,0.7-0.7,0.7h-1.9c-0.4,0-0.6-0.3-0.8-0.9l-0.6-3.2H522.6z"/>
                    <path className="foundation" d="M532.6,49.3c-0.3,0-0.6-0.4-0.6-0.6v-2.3c0-0.3,0.4-0.6,0.6-0.6h4.6v-7.1
                        c0-0.3,0.4-0.8,0.7-0.8h2.6c0.3,0,0.7,0.5,0.7,0.8v7.1h10.9c0.3,0,0.6,0.4,0.6,0.6v2.3c0,0.3-0.4,0.6-0.6,0.6h-10.9v15.8
                        c0,5.3,2.4,7.1,6,7.1c2.1,0,4.1-1.1,5.1-1.9c0.4-0.3,0.5-0.3,0.6-0.3c0.2,0,0.4,0.1,0.5,0.4l1,1.5c0.1,0.2,0.3,0.4,0.3,0.6
                        c0,0.1-0.1,0.4-0.4,0.6c-2.1,1.6-4.8,2.6-7.4,2.6c-5.9,0-9.8-3.2-9.8-10.6V49.3H532.6z"/>
                    <path className="foundation" d="M565.4,74.3c0,0.3-0.4,0.7-0.7,0.7h-2.6c-0.3,0-0.7-0.4-0.7-0.7V46.5c0-0.3,0.4-0.8,0.7-0.8
                        h2.6c0.3,0,0.7,0.5,0.7,0.8V74.3z M563.4,31.6c1.5,0,2.6,1.4,2.6,2.9c0,1.6-1.1,2.9-2.6,2.9c-1.5,0-2.6-1.3-2.6-2.9
                        C560.8,32.9,561.9,31.6,563.4,31.6"/>
                    <path className="foundation" d="M578.3,60.4c0,7.4,5.3,11.8,11.9,11.8c6.6,0,11.9-4.4,11.9-11.8c0-7.3-5.3-11.8-11.9-11.8
                        C583.6,48.6,578.3,53.1,578.3,60.4 M606.1,60.4c0,9.8-7.4,15.4-15.9,15.4c-8.6,0-15.9-5.6-15.9-15.4c0-9.7,7.4-15.4,15.9-15.4
                        C598.8,45,606.1,50.7,606.1,60.4"/>
                    <path className="foundation" d="M639.5,56.4c0-4.6-3.1-7.9-8.4-7.9c-5.8,0-10.2,3.6-12.2,8.1v17.7c0,0.3-0.4,0.7-0.7,0.7h-2.6
                        c-0.3,0-0.7-0.4-0.7-0.7V46.5c0-0.3,0.4-0.8,0.7-0.8h1.9c0.3,0,0.6,0.2,0.7,1l0.7,4.6h0.2c2.1-3.3,6.6-6.3,12.1-6.3
                        c8.1,0,12.2,4.7,12.2,11.4v17.9c0,0.3-0.4,0.7-0.7,0.7h-2.6c-0.3,0-0.7-0.4-0.7-0.7V56.4z"/>
                </g>
        </svg>
    )
}

export default Logo