import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from './header/header'
import Hamburger from './header/hamburger'
import Footer from './footer/footer'
import './layout.scss'

const Layout = ({ children, mode }) => {
  useEffect(() => {
    if (mode == 'dark') {
      document.getElementById('navBar').classList.add('startDark')
      document.querySelector('.hamburger-inner').classList.add('startMenuDark')
    }
  })
  return (
    <div>
      <Hamburger />
      <Header />
      <div>
        <main>
          {children}
        </main>
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout