import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import styles from './footer.module.scss'
import Container from '../container'
import LogoInline from '../../images/logo.inline.svg'

const Footer = () => {
  return (
      <footer>
        <div className={styles.greenSpan}></div>
          <Container>
              <div className={styles.greenBlock}>
                <Link to="./">
                  <LogoInline />
                  <br/>
                </Link>
              </div>
              <div className={styles.break}></div>
              <div className={styles.blueBlock}>
              <StaticQuery
                query={graphql`
                  query footerNavQuery {
                    allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "footer-menu"}}) {
                      edges {
                        node {
                          id
                          items {
                            object_slug
                            title
                            target
                            url
                          }
                        }
                      }
                    }
                  } 
                `}
                render={data => {
                  return (
                    <nav>
                      <ul>
                        <li><h5>MTA Foundation</h5></li>
                        {
                          data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map((link, i) => (
                            <li key={`footer-link-${i}`} >
                              <Link to={link.url.replace('http://157.245.172.255/', '/')} target={link.target} dangerouslySetInnerHTML={{ __html: link.title }} />
                            </li>
                          ))
                        }
                      </ul>
                    </nav>
                  )
                }}
              />
              </div>
          </Container>
        <div className={styles.blueSpan}></div>
    </footer>
  )
}

export default Footer