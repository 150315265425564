import React from "react"
import styles from "./container.module.scss"

const Container = ({children, isHero, style}) => {
    return (
        <div className={`${ (isHero) ? styles.heroContainer + ' heroContainer' : null } ${styles.container} container`} style={style ? {...style} : null}>
            {children}
        </div>
    )
}

export default Container