import React from 'react'
import { Link } from 'gatsby'
import styles from './header.module.scss'
import Announcements from './announcements'
import Logo from './logo'
import Container from '../container'

const Header = () => {

  return (
    <header className={styles.header} id="header">
      <div className={styles.navBar} id="navBar">
        <Announcements />
        <Container>
          <div className={styles.logo} id="hdrLogo">
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </Container>
      </div>
    </header>
  )
}

export default Header
