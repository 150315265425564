import React, { Component } from 'react'
import { graphql, StaticQuery } from "gatsby"
import Container from '../container'
import styles from './announcements.module.scss'

export class Announcements extends Component {
  render() {
    return(
      <div className={styles.announcements} id="announcements">
        <Container>
          <StaticQuery
            query={graphql`
              query alertQuery {
                wordpressAcfOptions {
                  options {
                    option_alert
                  }
                }
              } 
            `}
            render={data => {
              return (
                <h4>{data.wordpressAcfOptions.options.option_alert}</h4>
              )
            }}
          />          
        </Container>
      </div>
    )
  }
}

export default Announcements